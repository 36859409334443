import { createEnum } from '../utils/actionTypesHelper';

export default createEnum({
  aia: {
    predict: 'aia/predict',
  },
  procedure: {
    index: 'Procedure',
    list: 'procedure',
    escalated: 'procedure/escalated',
    active: 'procedures/active',
    inactive: 'procedures/inactive',
    edit: 'procedure/edit',
    item: 'procedure/:id',
    short: 'procedure/:id/short',
    filter: 'procedure/filterValues',
    statuses: 'procedure/statuses',
    splitByDate: 'procedure/:id/split',
    notes: 'procedure/:id/notes',
    end: 'procedure/:id/end',
    disabledState: 'procedure/disabled',
    withEventCounts: 'procedure/WithEventCounts',
  },
  dashboard: {
    newProceduresCount: 'Dashboard/new-procedures/count',
    newProceduresCountByMonth: 'Dashboard/new-procedures/count/groupedByMonth',
    newProceduresAverage: 'Dashboard/new-procedures/average',
    newReportsCount: 'Dashboard/new-reports/count',
    newReportsAverage: 'Dashboard/new-reports/average',
    completedEventsCount: 'Dashboard/events-completed/count',
    completedEventsAverage: 'Dashboard/events-completed/average',
    eventsCount: 'Dashboard/events/count',
    reportsCount: 'Dashboard/reports-completed/count',
    proceduresCount: 'Dashboard/procedures/count',
    newProceduresGroupedByDay: 'Dashboard/new-procedures/count/groupedByDay',
    newReportsGroupedByDay: 'Dashboard/new-reports/count/groupedByDay',
    completedEventsGroupedByDay:
      'Dashboard/events-completed/count/groupedByDay',
  },
  ecgData: {
    byPeriod: 'EcgHealthData/byPeriod',
    byPeriodLimited: 'EcgHealthData/byPeriodLimited',
  },
  healthData: {
    stripBeats: 'PatientHealthData/heartrate/period',
    ecgBeats: 'PatientHealthData/heartrate/period/extended',
    updatePatientEvents: 'PatientHealthData/patientevents',
    patientEvents: 'PatientHealthData/patientevents/period',
    patientEvent: 'PatientHealthData/patientevents/:id',
    heartRateStripSummary: 'PatientHealthData/heartrate/summary',
    hidePatientEvent: 'PatientHealthData/patientevents/:id/hide',
    toggleRotateEcg: 'PatientHealthData/patientevents/:id/toggle-rotate-ecg',
  },
  eventGroup: {
    index: 'EcgEventGroup',
    count: 'EcgEventGroup/count',
    review: 'EcgEventGroup/review',
    byPeriod: 'EcgEventGroup/byPeriod',
    escalated: 'EcgEventGroup/escalated',
    bulkReview: 'EcgEventGroup/bulk-review',
    abnormalities: 'EcgEventGroup/abnormalities',
    setSignificant: 'EcgEventGroup/setMostSignificant',
    bulkStatusUpdate: 'EcgEventGroup/bulk-status-update',
    templatesByPeriod: 'EcgEventGroup/ecgtemplates/byPeriod',
    templatesCount: 'EcgEventGroup/ecgtemplates/byPeriod/count',
    deleteEcgEvents: 'EcgEventGroup/delete',
    setInvertEcg: 'EcgEventGroup/toggle-rotate-ecg', // ?ids=[id]
    bulkSetInvertEcg: 'EcgEventGroup/toggle-rotate-ecg',
    setCustomPeriod: 'EcgEventGroup/set-custom-period',
    // not event group
    getIncludedDisclosure: 'included-disclosure-ecgs',
    includeDisclosure: 'include-disclosure-ecg',
    declineDisclosure: 'declude-disclosure-ecg',
  },
  sensorData: {
    index: 'SensorData',
    activity: 'SensorData/activity',
    temperature: 'SensorData/temperatures',
    bodyPosition: 'SensorData/body-positions',
  },
  generalHealthData: {
    index: 'GeneralHealthData',
    grouped: 'GeneralHealthData/grouped',
  },
  healthStatePreset: {
    tenant: 'HealthStatePreset/tenant/:id',
    physician: 'HealthStatePreset/physician/:id',
    procedure: 'HealthStatePreset/procedure/:id',
  },
  tenant: {
    index: 'tenant',
    list: 'tenant/list',
    login: 'tenant/login',
    filter: 'Tenant/FilterValues',
    batchUpdate: 'Tenant/statuses',
  },
  // non actual data
  files: {
    index: 'StorageFile',
    upload: 'StorageFile/upload',
    archive: 'StorageFile/archive',
    multiple: 'StorageFile/multiple',
    download: 'StorageFile/download/:id',
  },
  procedureSearch: 'procedure/search',
  icd: 'Icd10Code',
  patch: {
    index: 'patch',
    list: 'patch/list',
    login: 'patch/login',
    filter: 'patch/FilterValues',
    fieldValues: 'patch/fieldValues',
    status: '/patch/statuses',
  },
  report: {
    index: 'report',
    list: 'report/list',
    ongoing: 'report/ongoing',
    shortReport: '/Report/:reportId/short',
    uploadDocument: 'report/document',
    correlationData: 'report/correlations',
    sendReportFax: 'report/sendFax',
    fetchDocument: 'report/:fileId/document',
    ongoingDocument: 'report/ongoingDocument',
    sendReportEmail: 'report/sendEmail',
    escalatedDocument: 'report/escalatedDocument',
    ongoingTemplate: 'report/ongoing/template',
    reportNotes: 'report/ongoingReportNotes',
    escalated: {
      get: 'report/:procedureId/escalated',
      create: 'report/:procedureId/escalated',
      addEvent: 'report/:reportId/events',
      addPatientEvent: 'report/:reportId/patientevents',
      generate: 'Report/:reportId/generate',
      editEventNotes: 'report/:reportId/events/:eventGroupId/notes',
      editPatientEventNotes:
        'report/:reportId/patientevents/:patientEventId/eventgroup/:eventGroupId/notes',
      removeEvent: 'report/:reportId/events/:eventGroupId',
      removePatientEvent: 'report/:reportId/patientevents/:patientEventId',
    },
    validate: 'report/validate',
    updateReportFileId: 'report/updateReportFileId',
    ongoingStudySummary: 'report/ongoingStudySummary',
    submitEditsOptions: 'report/:id/submit-edits-options',
    submitStatusAccepted: 'report/:id/submit-edits-status-accepted',
  },
  user: {
    index: 'Account',
    list: 'Account/List',
    filter: 'Account/filterValues',
    item: 'Account/:id',
    status: 'Account/statuses',
  },
  accountAction: {
    activityByEntity: 'AccountAction',
    activityByAccount: 'AccountAction/summary',
    kitsAssignedToProcedure: 'AccountAction/kitsAssignedToProcedure',
    proceduresByAssignedKit: 'AccountAction/proceduresByAssignedKit',
    lastAssignedKitToProcedure: 'AccountAction/lastAssignedKitToProcedure',
  },
  profile: {
    index: 'Account',
    account: 'Account/:id',
    archive: 'Account/archive/:id',
    unarchive: 'Account/unarchive/:id',
    updatePassword: 'Account/change-password',
  },
  kit: {
    index: 'Kit',
    assignees: 'Kit/assignees',
    filter: 'Kit/filterValues',
    item: 'Kit/:id',
    status: 'Kit/statuses',
    bulkTenantUpdate: 'Kit/bulk-tenant-update',
  },
  specialNote: {
    index: 'SpecialNote',
  },
  physician: {
    index: 'Physician',
    list: 'Physician/List',
    item: 'Physician/:id',
    status: 'Physician/statuses',
    filter: 'Physician/filterValues',
    reviewReport: 'Physician/ReviewReport',
    reviewReportByFileId: 'Physician/ReviewReportByFileId',
    recentProcedures: 'Physician/RecentProcedures',
    loginToPhysician: 'Physician/login/:id',
    updatePassword: 'Physician/:id/update-password',
  },
  patient: {
    index: 'Patient',
    list: 'Patient/List',
    filter: 'Patient/filterValues',
    item: 'Patient/:id',
    status: 'Patient/statuses',
    lockPatients: 'Patient/LockPatients',
    unlockPatients: 'Patient/UnlockPatients',
  },
  state: {
    index: 'state',
    item: 'state/:id',
  },
  country: {
    index: 'country',
    item: 'country/:id',
  },
  phone: {
    index: 'Phone',
    list: 'Phone/List',
    item: 'Phone/:id',
    filter: 'Phone/filterValues',
    fieldValues: 'Phone/fieldValues',
    status: 'Phone/statuses',
  },
  simCard: {
    index: 'SimCard',
    list: 'SimCard/List',
    item: 'SimCard/:id',
    statuses: 'SimCard/statuses',
    filter: 'SimCard/filterValues',
    fieldValues: 'SimCard/fieldValues',
  },
  role: {
    index: 'Role',
  },
  contact: {
    index: 'Contact',
    list: 'Contact/List',
    item: 'Contact/:id',
    assign: 'Contact/:id/assignToAccount/:accountId',
    unAssign: 'Contact/:id/unassignToAccount/:accountId',
    updatePassword: 'Contact/:id/update-password',
  },
  counter: {
    events: 'counter/ecgEventGroups',
    procedures: 'counter/procedures',
    patientEvents: 'counter/patientevents',
  },
  firmware: {
    index: 'Firmware',
    upload: 'Firmware/upload',
    archive: 'Firmware/archive',
    multiple: 'Firmware/unarchive',
    download: 'Firmware/download/:id',
  },
  pageInfo: {
    visitor: 'pageInfo/page-visitor',
  },
});
