import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import {
  CurveLine,
  BeatRMark,
  Grid as D3Grid,
  BeatDurationLabel,
  Container as D3Container,
} from 'common/components/D3Components';
import {
  EventLine,
  SymptomRect,
  BeatOutline,
} from 'common/components/D3Components/eventStrip';
import paths from 'common/constants/path';
import useRouterInfo from 'common/hooks/useRouterInfo';
import { settingProps } from 'common/constants/sharedPropTypes';
import { getBeatIndex } from 'common/components/D3Components/utils';
import {
  getEcg,
  getBeats,
  getEventGroups,
  getPatientEvents,
  getVisibleRPoints,
  getVisibleBeatsDuration,
} from 'common/modules/eventStrip/ducks/selectors';
import MeasureRuler from 'common/modules/eventStrip/components/MeasureRuler';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';

import EcgEventsSelector from './EcgEventsSelector';

const graphOptions = {
  xPropKey: 'time',
  yPropKey: 'value',
  padding: { top: 0, left: 0, right: 0, bottom: 0 },
};

const eventsSelectorOptions = { withoutHRMin: true, inRange: true };

const EventStripGraph = (props) => {
  const {
    width,
    height,
    settings,
    onClick,
    position,
    inverted,
    aiEvents,
    resource,
    onPEClick,
    focusOptions,
    visibleRange,
    analyzedBeats,
    selectedEvent,
    setVisibleRange,
    handleEventSelect,
    isMeasureRulerShown,
    selectedPatientEvent,
  } = props;
  const router = useRouterInfo();
  const beats = useSelector(getBeats(resource));
  const visibleRPoints = useSelector(getVisibleRPoints(resource, visibleRange));
  const ecgEvents = useSelector(
    getEventGroups(resource, eventsSelectorOptions, visibleRange)
  );
  const visibleBeatsDuration = useSelector(
    getVisibleBeatsDuration(resource, visibleRange)
  );
  const patientEvents = useSelector(
    getPatientEvents(resource, true, visibleRange)
  );

  const ecgData = useSelector(getEcg(resource, true, 20, visibleRange));

  const isMonitoringPage = useMemo(
    () => router?.url === paths.private.procedures.monitoring.index,
    [router]
  );

  const lineTransform = useMemo(
    () =>
      !isMonitoringPage && inverted
        ? `scale(1, -1) translate(0, -${height + 30})`
        : undefined,
    [height, inverted, isMonitoringPage]
  );

  const clickHandler = useCallback(
    (point, isShiftKey) => {
      if (!beats.length) return;

      const index = getBeatIndex(beats, point);

      if (index <= 0) {
        return;
      }

      if (!isShiftKey) {
        const start = beats[index - 1][graphOptions.xPropKey];
        const end = beats[index][graphOptions.xPropKey];

        onClick([start && new Date(start), end && new Date(end)]);

        return;
      }

      if (!position) return;

      const isLeftDirection = point.getTime() < position[0].getTime();
      if (isLeftDirection) {
        const start = new Date(beats[index - 1][graphOptions.xPropKey]);
        onClick([start, position[1]]);
      } else {
        const end = new Date(beats[index][graphOptions.xPropKey]);
        onClick([position[0], end]);
      }
    },
    [beats, position, onClick]
  );

  const PEClickHandler = useCallback(
    (event) => {
      onPEClick(event);
    },
    [onPEClick]
  );

  const onScrollHandler = useCallback(
    (inv, sc, range) => {
      if (!range || !setVisibleRange) {
        return;
      }

      setVisibleRange(range);
    },
    [setVisibleRange]
  );

  const scrollDebounce = useDebouncedCallback(onScrollHandler, 300);

  const eventGroups = useMemo(
    () => [ecgEvents, aiEvents].flat(),
    [ecgEvents, aiEvents]
  );

  const foreignObjects = useMemo(
    () => [
      <EcgEventsSelector
        key="EcgEventsSelector"
        eventGroups={eventGroups}
        selectedBeatRange={position}
        selectedEvent={selectedEvent}
        handleEventSelect={handleEventSelect}
        selectedPatientEvent={selectedPatientEvent}
      />,
    ],
    [
      position,
      eventGroups,
      selectedEvent,
      handleEventSelect,
      selectedPatientEvent,
    ]
  );

  return (
    <D3Container
      zoomable
      forceScroll
      width={width}
      height={height}
      focus={focusOptions}
      options={graphOptions}
      stripSettings={settings}
      onClick={clickHandler}
      onScrollHandler={scrollDebounce}
      foreignObjects={foreignObjects}
    >
      <D3Grid color="gray" strokeWidth={0.5} />

      {visibleBeatsDuration.map((d) => (
        <BeatDurationLabel {...d} key={d.time} />
      ))}

      {!!analyzedBeats.length &&
        analyzedBeats.map((d) => (
          <BeatRMark {...d} fill="red" isAi key={d.time} />
        ))}

      {visibleRPoints.map((d) => (
        <BeatRMark {...d} key={d.time} fill="green" />
      ))}

      <CurveLine
        data={ecgData}
        color="black"
        strokeWidth={2}
        transform={lineTransform}
      />

      {position && beats.length && <BeatOutline position={position} />}

      {isMeasureRulerShown && beats.length && (
        <MeasureRuler beats={beats} position={position} />
      )}

      {ecgEvents.map((ev) => (
        <EventLine key={ev.id} event={ev} bottom={80} />
      ))}

      {aiEvents.map((ev) => {
        const bottomLevel =
          ev.abnormalityType === abnormalityTypeEnum.ve ? 10 : 40;
        return <EventLine ai key={ev.id} bottom={bottomLevel} event={ev} />;
      })}

      {patientEvents.map((event) => (
        <SymptomRect
          id={event.id}
          key={event.id}
          position={event.date}
          onClick={() => PEClickHandler(event)}
        />
      ))}
    </D3Container>
  );
};

EventStripGraph.defaultProps = {
  onClick: () => null,
};

EventStripGraph.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  settings: settingProps,
  resource: PropTypes.string,
  aiEvents: PropTypes.arrayOf(PropTypes.any),
  position: PropTypes.arrayOf(PropTypes.any),
  visibleRange: PropTypes.arrayOf(PropTypes.string),
  onPEClick: PropTypes.func,
  analyzedBeats: PropTypes.arrayOf(PropTypes.shape({})),
  selectedEvent: PropTypes.shape({}),
  setVisibleRange: PropTypes.func,
  handleEventSelect: PropTypes.func,
  isMeasureRulerShown: PropTypes.bool,
  focusOptions: PropTypes.shape({
    center: PropTypes.string,
    yDomain: PropTypes.arrayOf(PropTypes.number),
    xDomain: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  }),
  selectedPatientEvent: PropTypes.shape({}),
};

const isEqual = (prev, next) => {
  const [prevStartRange] = prev.visibleRange || [];
  const [nextStartRange] = next.visibleRange || [];

  return (
    prev.width === next.width &&
    prev.center === next.center &&
    prev.onClick === next.onClick &&
    prev.loading === next.loading &&
    prev.aiEvents === next.aiEvents &&
    prev.inverted === next.inverted &&
    prev.resource === next.resource &&
    prev.settings === next.settings &&
    prev.position === next.position &&
    prev.focusOptions === next.focusOptions &&
    prev.analyzedBeats === next.analyzedBeats &&
    prev.selectedEvent === next.selectedEvent &&
    prev.isMeasureRulerShown === next.isMeasureRulerShown &&
    prevStartRange === nextStartRange
  );
};

export default memo(EventStripGraph, isEqual);
